<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0">
        <dx-data-grid
          id="planManagementGrid"
          ref="planManagementDataGridRef"
          width="inherit"
          :height="setHeight"
          :data-source="planManagementDataSource"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :hover-state-enabled="true"
          :row-alternation-enabled="false"
          :customize-columns="customizeColumns"
          @initialized="onInitialized"
          @content-ready="onContentReady"
          @toolbar-preparing="onToolbarPreparing"
          @editor-preparing="onEditorPreparing"
        >
          <dx-sorting mode="single" />
          <dx-remote-operations :paging="true" :sorting="false" :filtering="false" />
          <dx-column-chooser :enabled="true" mode="select" title="Column Chooser" />
          <dx-search-panel :visible="true" :width="200" placeholder="Search..." />

          <dx-column data-field="planCategory" caption="Category" :width="100" alignment="center" :customize-text="customizePlanCategoryText" />
          <dx-column data-field="planType" caption="Plan Type" alignment="left" />
          <dx-column data-field="planName" caption="Plan Name" alignment="left" />
          <dx-column data-field="itemFee" :width="100" :format="{ type: 'currency', precision: 2 }" />
          <dx-column data-field="minItemCount" caption="Min" :width="100" alignment="right" />
          <dx-column data-field="maxItemCount" caption="Max" :width="100" alignment="right" />
          <dx-column data-field="discount" caption="Discount" :width="100" alignment="right" />
          <dx-column data-field="discountExp" caption="Discount Exp" :width="120" alignment="center" data-type="date" />
          <dx-column data-field="status" alignment="center" :width="100" cell-template="statusTemplate" />
          <template #statusTemplate="{ data }">
            <div
              class="badge badge-pill text-capitalize px-1"
              :class="getStatusFormatted(data.value)"
            >
            {{ data.value }}
            </div>
          </template>
          <dx-column data-field="visibility" alignment="center" :width="100" cell-template="visibilityTemplate" />
          <template #visibilityTemplate="{ data }">
            <div
              class="badge badge-pill text-capitalize px-1"
              :class="getVisibilityFormatted(data.value)"
            >
            {{ data.value }}
            </div>
          </template>
          <dx-column data-field="warehouseName" caption="Warehouse" :width="140" alignment="center" />
          <dx-column data-field="id" caption="Actions" alignment="center" cell-template="itemActions" :width="120" :fixed="true" fixed-position="left" />
          <template #itemActions="{data}">
            <div>
              <dx-util-button
                v-if="hasEnrollmentPermission"
                type="success"
                icon="plus"
                class="mr-half"
                hint="Enroll the plan"
                @click="enrollPlan(data)"
              />
              <dx-util-button
                v-if="hasPermission"
                type="warning"
                icon="bi bi-pencil-square"
                class="mr-half"
                hint="Edit the plan"
                @click="editPlan(data)"
              />
              <dx-util-button
                v-if="hasPermission"
                type="danger"
                icon="bi bi-trash-fill"
                hint="Delete the plan"
                @click="deleteItem(data)"
              />
            </div>
          </template>
          <template #filterToolbar>
            <div class="d-flex flex-row align-items-center">
              <div class="mr-1 w-35">
                <dx-util-select-box
                  v-model="selectedWarehouse"
                  :data-source="warehouses"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="searchByFilter"
                />
              </div>
              <div class="mr-1 w-35">
                <dx-util-select-box
                  v-model="selectedPlanCategory"
                  :data-source="planCategories"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="searchByFilter"
                />
              </div>
              <div class="mr-1 w-35">
                <dx-util-select-box
                  v-model="selectedPlanType"
                  :data-source="planTypes"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="searchByFilter"
                />
              </div>
              <div v-if="hasPermission" class="mr-1 w-35">
                <dx-util-select-box
                  v-model="selectedStatus"
                  :data-source="statusList"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="searchByFilter"
                />
              </div>
            </div>
          </template>

          <dx-paging :page-size="pagerOptions.pageSize" />
          <dx-pager
            :allowed-page-sizes="pagerOptions.allowedPageSizes"
            :show-info="pagerOptions.showInfo"
            :show-navigation-buttons="pagerOptions.showNavigationButtons"
            :show-page-size-selector="pagerOptions.showPageSizeSelector"
            :visible="pagerOptions.visible"
          />

        </dx-data-grid>
      </div>
      <div class="col-md-12 px-0">
        <plan-form
          :key="rerenderKey"
          :warehouses="warehouses"
          :plan-types="planTypes"
          :current-row="selectedRow"
          :show-popup="isVisible"
          @form-saved="reloadAfterUpdate"
          @form-closed="isVisible=false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PlanSearchFilter from '@/http/models/search-filters/PlanSearchFilter'
import customerPlanCategoryEnum, { getCustomerPlanCategoryList } from '@/enums/customerPlanCategory.enum.js'
import customerPlanStatusEnum, { getPlanStatusList, getPlanStatusOptions } from '@/enums/customerPlanStatus.enum.js'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import planManagementService from '@/http/requests/plan/planManagementService'
import shipService from '@/http/requests/ship/shipService'
import { planManagementDataSource } from './planManagementStore'

export default {
  components: {
    'plan-form': () => import('./PlanForm.vue'),
  },
  mixins: [GridBase],
  data: () => ({
    planManagementDataSource,
    statusList: getPlanStatusList(),
    statusOptions: getPlanStatusOptions(),
    selectedStatus: customerPlanStatusEnum.RESERVED_ALL.value,
    warehouses: [],
    selectedWarehouse: 0,
    planTypes: [],
    selectedPlanType: 0,
    isVisible: false,
    rerenderKey: 0,
    selectedRow: {},
    planEnrollment: {
      companyId: 0,
      planId: 0,
      status: customerPlanStatusEnum.PENDING.value,
    },
    planCategories: getCustomerPlanCategoryList(),
    selectedPlanCategory: customerPlanCategoryEnum.ALL.value,
  }),
  computed: {
    dataGrid() {
      return this.$refs.planManagementDataGridRef.instance
    },
    hasPermission() {
      return this.$can('read', 'resource_management_plans')
    },
    hasEnrollmentPermission() {
      return this.$can('read', 'resource_management_customer_plan_enrollment')
    },
    pageableResult: {
      get() {
        return this.pageable.toCreatePayload()
      },
    },
  },
  mounted() {
    this.initialLoad()
    this.getPlanTypes()
    this.getWarehouses()
  },
  methods: {
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      toolbarItems.unshift({
        location: 'before',
        template: 'filterToolbar',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            this.reload()
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxButton',
        visible: this.hasPermission,
        options: {
          icon: 'plus',
          onClick: () => {
            this.isVisible = true
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxButton',
        visible: this.hasPermission,
        options: {
          type: 'success',
          text: 'Plan Types',
          onClick: () => {
            this.goToPlanTypes()
          },
        },
        location: 'after',
      })
    },
    onEditorPreparing(e) {
      if (e.parentType === 'searchPanel') {
        e.editorOptions.onValueChanged = arg => {
          const query = arg.component.option('value')
          if (!query) {
            this.query = query.trim()
            e.component.searchByText('')
            this.searchByFilter()
          }
        }
        e.editorOptions.onKeyDown = arg => {
          if (arg.event.keyCode === 13) {
            let query = arg.component.option('value')
            query = query.trim()
            arg.component.option('value', query)
            this.query = query
            this.searchByFilter()
            e.component.searchByText(query)
          }
          if (arg.event.keyCode === 27) {
            e.component.searchByText('')
            arg.component.option('value', '')
            this.query = ''
            this.searchByFilter()
          }
        }
      }
    },
    initialLoad() {
      PlanSearchFilter.setDefaultFilters()
      PlanSearchFilter.status = this.selectedStatus
      if (!this.hasPermission) {
        PlanSearchFilter.status = customerPlanStatusEnum.ACTIVE.value
      }
      const filters = PlanSearchFilter.getFilters()
      const dataSource = this.dataGrid.getDataSource()
      dataSource.searchValue(filters)
      dataSource.load()
    },
    searchByFilter() {
      PlanSearchFilter.setDefaultFilters()
      PlanSearchFilter.accountNo = this.accountNo
      PlanSearchFilter.planType = this.selectedPlanType
      PlanSearchFilter.warehouseId = this.selectedWarehouse
      PlanSearchFilter.planCategory = this.selectedPlanCategory
      PlanSearchFilter.status = this.selectedStatus
      PlanSearchFilter.visibility = 8 // all(public and private)
      const filters = PlanSearchFilter.getFilters()
      const dataSource = this.dataGrid.getDataSource()
      dataSource.searchValue(filters)
      dataSource.reload()
    },
    reload() {
      PlanSearchFilter.setDefaultFilters()
      PlanSearchFilter.status = this.hasPermission ? customerPlanStatusEnum.RESERVED_ALL.value : customerPlanStatusEnum.ACTIVE.value
      const filters = PlanSearchFilter.getFilters()
      const dataSource = this.dataGrid.getDataSource()
      dataSource.searchValue(filters)
      dataSource.reload()
      this.selectedPlanCategory = customerPlanCategoryEnum.ALL.value
      this.selectedPlanType = 0
      this.selectedWarehouse = 0
      this.selectedStatus = customerPlanStatusEnum.RESERVED_ALL.value
      this.accountNo = ''
      this.dataGrid.clearFilter('search')
    },
    reloadAfterUpdate() {
      this.isVisible = false
      this.reload()
    },
    customizePlanCategoryText(cellInfo) {
      return `${cellInfo.value.toUpperCase()}`
    },
    getStatusFormatted(status) {
      if (status.toLowerCase() === 'active') return 'badge-success'
      if (status.toLowerCase() === 'passive') return 'badge-dark'
      return 'badge-primary'
    },
    getVisibilityFormatted(visibility) {
      if (visibility.toLowerCase() === 'public') return 'badge-success'
      if (visibility.toLowerCase() === 'private') return 'badge-warning'
      return 'badge-primary'
    },
    getWarehouses() {
      shipService.fetchWarehouses().then(result => {
        const { data } = result
        if (data.length > 1) {
          this.warehouses.push({ value: 0, text: 'Warehouse (All)' })
        }
        result.data.forEach(element => {
          if (element.name.toLowerCase() !== 'common') {
            this.warehouses.push({ value: element.id, text: element.name })
          }
        })
        this.selectedWarehouse = 0
        if (this.warehouses.length === 1) {
          this.selectedWarehouse = this.warehouses[0].value
        }
      })
    },
    getPlanTypes() {
      this.planTypes = []
      this.planTypes.push({ value: 0, text: 'Plan Type (All)' })
      planManagementService.fetchAllPlanTypes().then(result => {
        result.data.forEach(element => {
          this.planTypes.push({ value: element.id, text: element.typeName })
        })
      })
    },
    editPlan(e) {
      this.selectedRow = e.row.data
      this.isVisible = true
    },
    goToPlanTypes() {
      this.$router.push({ name: 'route-plans-plan-types' })
    },
    enrollPlan(payload) {
      const plan = payload.row.data
      this.$swal({
        title: 'Are you sure you want to enroll to this Plan?',
        text: 'Once the service provider approves your plan, it will show as active in your plans!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Enroll',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const userData = JSON.parse(localStorage.getItem('userData'))
          this.planEnrollment.planId = plan.id
          this.planEnrollment.companyId = userData.companyId
          planManagementService.createPlanEnrollment(this.planEnrollment).then(() => {
            this.$swal({
              icon: 'success',
              title: 'Enrolled!',
              text: 'You have been enrolled to this plan. The current status is pending. Once the service provider approves your plan, it will show as active in your plans!',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
        }
      })
    },
    deleteItem(payload) {
      const id = payload.row.data.id
      this.$swal({
        title: 'Are you sure you want to delete this Plan record?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((async result => {
        if (result.value) {
          const dataSource = this.dataGrid.getDataSource()
          const res = await planManagementService.deletePlanById(id)
          if (res) {
            this.$swal({
              icon: 'success',
              title: 'Deleted!',
              text: 'Your Plan record has been deleted.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            dataSource.reload()
          }
        }
      }))
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
